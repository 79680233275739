import Router from "./Router";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./css/main.css";
import "./css/responsive.css";
import { useEffect } from "react";
import { getLocalData } from "./Utils/LocalStorageServices";
import { useLocation } from "react-router-dom";

function App() {
  const token = getLocalData("_token");
  const { pathname } = useLocation();

  useEffect(() => {
    if (token) {
      document.body.classList.add("inner_app");
      document.body.classList.remove("outer_app");
    } else {
      document.body.classList.add("outer_app");
      document.body.classList.remove("inner_app");
    }
  }, [pathname, token]);

  return (
    <>
      <Router />
    </>
  );
}

export default App;
