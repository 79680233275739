//set data in local storage using key value pair
export const setLocalData = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

//get data from localstorage using key
export const getLocalData = (key) => {
    return JSON.parse(localStorage.getItem(key));
}

//remove particular key and value data from local storage
export const removeLocalData = (key) => {
    localStorage.removeItem(key);
}