import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const Contact = lazy(() => import("./Pages/ContactUs"));
const WhatYouLearn = lazy(() => import("./Pages/WhatYouLearn"));
const Home = lazy(() => import("./Pages/Home"));
const SignUp = lazy(() => import("./Pages/SignUp"));
const Error = lazy(() => import("./Pages/Error"));
const Login = lazy(() => import("./Pages/Login"));
const ForgotPassword = lazy(() => import("./Pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./Pages/ResetPassword"));
const OtpVerify = lazy(() => import("./Pages/OtpVerify"));
const UserProfile = lazy(() => import("./Pages/UserProfile"));
const Loader = lazy(() => import("./Components/UI/LinkButton/Loader"));
const EditUserProfile = lazy(() => import("./Pages/EditUserProfile"));

const Protected = lazy(() => import("./Utils/Protected"));
const PublicRoute = lazy(() => import("./Utils/PublicRoute"));
const About = lazy(() => import("./Pages/About"));
const Achievements = lazy(() => import("./Pages/Achievements"));
const Checkout = lazy(() => import("./Components/Checkout/Checkout"));
const Layout = lazy(() => import("./Components/Layouts"));

const Privacy = lazy(() => import("./Pages/Privacy"));
const Terms = lazy(() => import("./Pages/Terms"));
const Faq = lazy(() => import("./Pages/Faq"));
const Plan = lazy(() => import("./Pages/Plan"));

const PlanOuter = lazy(() => import("./Pages/PlanOuter"));
const ScrollTop = lazy(() => import("./Components/scrolltop/ScrollTop"));

const Router = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <ScrollTop />
        <Routes>
          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/what-you-learn" element={<WhatYouLearn />} />
          <Route exact path="/how-it-works" element={<About />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/otp-verify" element={<OtpVerify />} />
          <Route exact path="/about-us" element={<About />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          {/* <Route path='/plan' element={<Plan />} /> */}
          <Route path="/faq" element={<Faq />} />

          {/* Protected routes */}
          <Route element={<Protected />}>
            <Route exact path="/*" element={<Layout />} />
            <Route exact path="/user-profile" element={<UserProfile />} />
            <Route
              exact
              path="/edit-user-profile"
              element={<EditUserProfile />}
            />
            <Route path="/achievements" element={<Achievements />} />
            <Route exact path="/plan" element={<Plan />} />
            <Route path="/checkout" element={<Checkout />} />
            {/* <Route path='*' element={<Error />} /> */}
          </Route>

          {/* public routes*/}
          <Route element={<PublicRoute />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/plans" element={<PlanOuter />} />
            <Route exact path="/register" element={<SignUp />} />
            <Route exact path="/login" element={<Login />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Router;
